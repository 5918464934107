import {
  call,
  delay,
  getContext,
  put,
  race,
  select,
  spawn,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  closeModalsAndWidgets,
  logWidgetEvent,
  runProductRequestPolling,
  setCriticalIssueOccurred,
  setCurrentWidgetFlow,
  setDataPullProcessingTime,
  setSwitchToNextVendor,
  setWidgetConfiguration,
  setWidgetFlow,
  setYodleeConnection,
  stopProductRequestPolling,
  toggleProductRequestProcessing,
  toggleWidgetRender,
} from "../actions/widget-actions";
import {
  cancelYodleeWidgetConfigPolling,
  setIsLoading,
  setYodleeWidgetConfigurationAction,
} from "../actions/yodlee-widget-actions";
import {
  YODLEE_CONFIG_CANCEL_STATUS_POLLING,
  YODLEE_CONNECTION_UPDATE,
  YODLEE_HANDLE_YODLEE_WIDGET_CLOSE,
  YODLEE_HANDLE_YODLEE_WIDGET_ERROR,
  YODLEE_HANDLE_YODLEE_WIDGET_EVENT,
  YODLEE_HANDLE_YODLEE_WIDGET_SUCCESS,
  YODLEE_INFO_CODES,
  YODLEE_UNPROCESSABLE_ERROR_CODES,
  YODLEE_WARNING_ADDITIONAL_STATUSES,
  YODLEE_WARNING_CODES,
  YODLEE_WIDGET_CONFIG_UPDATE,
  YODLEE_WIDGET_INIT,
  YODLEE_WIDGET_UPDATE_DELAY,
} from "../constants/yodlee-widget";
import { ApiErrorMessagesMapper, loginFailureCallbackPayload } from "../services/api-client";
import {
  getWidgetConfiguration,
  isProductRequestFrozen,
  sendMessageToNative,
} from "../shared/masamune-widget-utils";
import {
  findOrCreateYodleeConnection,
  hideFastlink,
  updateYodleeConnectionRequest,
} from "../shared/yodlee-widget-utils";
import { CONNECTION_FREEZE } from "../constants/masamune-widget";
import vendorErrorTypes from "../config/vendor-error-types.json";

const vendor = "yodlee";

// Checks for already running pull to prevent duplicate pulls
const withProductRequestFreezeMiddleware = (worker) =>
  function* (action) {
    const globalConfig = yield select((state) => state.globalConfig);
    let requestFreeze = yield call(isProductRequestFrozen, globalConfig.productRequest?.product_request_id, globalConfig);

    if (requestFreeze) {
      yield put(setCurrentWidgetFlow(CONNECTION_FREEZE));
      yield put(setIsLoading(false));
      return yield call(hideFastlink);
    }

    // If connection processing is not true, proceed with the original worker
    yield call(worker, action);
  };

function* processYodleeWidgetCallbackErrorWorker(action) {
  const { eventPayload } = action.payload;

  const globalConfig = yield select((state) => state.globalConfig);
  const accessToken = globalConfig.accessToken;
  const { onError, onLoginFailure } = globalConfig.clientCallbackFunctions;
  const rollbar = yield getContext("rollbar");

  const connectionUpdateParams = {
    connectionId: globalConfig.yodleeConnection?.id,
    productRequestId: globalConfig.productRequest?.product_request_id,
    edgeClientId: globalConfig.edgeClientId,
    vendorInstitutionId: eventPayload?.providerId,
    status: "login_failed",
    accessToken: globalConfig.accessToken,
    vendorResponse: eventPayload,
  };

  try {
    yield put(logWidgetEvent({ eventPayload, accessToken, vendor }));
    yield put(setIsLoading(true));
    yield call(updateYodleeConnectionRequest, connectionUpdateParams);

    const errorType = vendorErrorTypes[vendor][eventPayload["additionalStatus"]];
    if (!errorType) rollbar.warning(`Undefined ${vendor} error type: ${eventPayload["additionalStatus"]}`);
    yield call(onLoginFailure, loginFailureCallbackPayload(errorType, eventPayload));

    if (
      YODLEE_UNPROCESSABLE_ERROR_CODES.indexOf(
        eventPayload["additionalStatus"]
      ) !== -1 ||
      eventPayload["fnToCall"] === "errorHandler"
    ) {
      const yodleeProviderErrorLog = logLevel => {
        rollbar[logLevel]("Got error from Yodlee provider", eventPayload, {
          params: connectionUpdateParams
        });
      };

      if (YODLEE_INFO_CODES.includes(eventPayload["code"])) {
        yodleeProviderErrorLog("info");
      }
      else if (
        YODLEE_WARNING_CODES.includes(eventPayload["code"]) ||
        YODLEE_WARNING_ADDITIONAL_STATUSES.includes(eventPayload["additionalStatus"])
      ) {
        yodleeProviderErrorLog("warning");
      }
      else yodleeProviderErrorLog("error");

      yield call(
        onError,
        ApiErrorMessagesMapper(eventPayload, {
          isLastVendor: globalConfig.isLastVendor,
        })
      );
    }
  } catch (e) {
    rollbar.error("Failed to process error callback for Yodlee", e, {
      params: connectionUpdateParams,
    });

    yield call(
      onError,
      ApiErrorMessagesMapper(e, { isLastVendor: globalConfig.isLastVendor })
    );

    /// Unmount on authentication error
    if (e?.cause?.statusCode === 401) {
      return yield put(toggleWidgetRender());
    }
    /// Switch to next on bad requests and server errors
    if (
      e?.cause?.statusCode === 500 ||
      e?.cause?.statusCode === 422 ||
      e?.cause?.statusCode === 400
    ) {
      yield put(setSwitchToNextVendor());
      return yield put(setCriticalIssueOccurred());
    }
    /// Switch to the next vendor if the Sophtron code itself trows some errors
    yield put(setSwitchToNextVendor());
    yield put(setCriticalIssueOccurred());
    yield put(setWidgetConfiguration({ showModal: true }));
  }
}

function* processYodleeWidgetCallbackSuccessWorker(action) {
  const { eventPayload, connectionId, productRequestId } = action.payload;
  const rollbar = yield getContext("rollbar");
  const {
    accessToken,
    clientCallbackFunctions: { onError, onSuccess },
    edgeClientId,
    isLastVendor,
    productRequest
  } = yield select(state => state.globalConfig);

  yield put(logWidgetEvent({ eventPayload, vendor }));
  yield call(hideFastlink);

  const connectionUpdateParams = {
    connectionId,
    productRequestId,
    edgeClientId,
    accessToken,
    vendorResponse: eventPayload,
    vendorInstitutionId: eventPayload?.providerId,
    status: "login_success",
  };

  try {
    yield put(setDataPullProcessingTime());
    yield call(updateYodleeConnectionRequest, connectionUpdateParams);
    yield put(toggleProductRequestProcessing(true));
    yield put(setWidgetFlow());
    // Start product request polling
    yield put(runProductRequestPolling({ productRequestId, accessToken }));
  } catch (e) {
    rollbar.error("Failed to process success callback for Yodlee", e, {
      params: connectionUpdateParams,
    });
    yield put(setSwitchToNextVendor());
    yield put(setCriticalIssueOccurred());
    yield put(stopProductRequestPolling());
    yield put(cancelYodleeWidgetConfigPolling());
    yield call(
      onError,
      ApiErrorMessagesMapper(e, { isLastVendor })
    );
  }
  productRequest.ibv_report_status = "initiated"; // Workaround to fix the issue with the product request status on initial callback
  yield put(setIsLoading(false));
  yield call(onSuccess, productRequest);
}

function* processYodleeWidgetCallbackEventWorker(action) {
  const { eventPayload } = action.payload;
  const globalConfig = yield select((state) => state.globalConfig);
  const accessToken = globalConfig.accessToken;

  yield put(logWidgetEvent({ eventPayload, accessToken, vendor }));

  if (eventPayload.type === "OPEN_EXTERNAL_URL") {
    yield call(sendMessageToNative, {
      event: "open_url",
      url: eventPayload.data.url,
    });
  }
}

function* processYodleeWidgetCallbackCloseWorker(action) {
  const { eventPayload } = action.payload;

  const globalConfig = yield select((state) => state.globalConfig);
  const { onError } = globalConfig.clientCallbackFunctions;
  const accessToken = globalConfig.accessToken;

  if (
    YODLEE_UNPROCESSABLE_ERROR_CODES.indexOf(
      eventPayload["additionalStatus"]
    ) !== -1 ||
    eventPayload["fnToCall"] === "errorHandler"
  ) {
    yield put(setSwitchToNextVendor());
    yield put(setCriticalIssueOccurred());
    yield call(
      onError,
      ApiErrorMessagesMapper(eventPayload, {
        isLastVendor: globalConfig.isLastVendor,
      })
    );
  }

  if (!globalConfig?.widgetConfiguration?.features?.bankSelection) {
    yield put(setSwitchToNextVendor());
  }

  yield put(logWidgetEvent({ eventPayload, accessToken, vendor }));

  if (globalConfig?.widgetConfiguration?.features?.bankSelection) {
    yield put(closeModalsAndWidgets());
    yield put(
      setWidgetConfiguration({
        retryWithBankSelection: true,
        showModal: true,
      })
    );
  } else {
    yield put(setSwitchToNextVendor());
  }

  yield call(hideFastlink);
}

const processYodleeWidgetCallbackCloseWorkerWithMiddleware = withProductRequestFreezeMiddleware(processYodleeWidgetCallbackCloseWorker);
const processYodleeWidgetCallbackSuccessWorkerWithMiddleware = withProductRequestFreezeMiddleware(processYodleeWidgetCallbackSuccessWorker);
const processYodleeWidgetCallbackEventWorkerWithMiddleware = withProductRequestFreezeMiddleware(processYodleeWidgetCallbackEventWorker);
const processYodleeWidgetCallbackErrorWorkerWithMiddleware = withProductRequestFreezeMiddleware(processYodleeWidgetCallbackErrorWorker);

function* watchYodleeWidgetCallbacks() {
  yield takeEvery(
    YODLEE_HANDLE_YODLEE_WIDGET_CLOSE,
    processYodleeWidgetCallbackCloseWorkerWithMiddleware
  );
  yield takeEvery(
    YODLEE_HANDLE_YODLEE_WIDGET_SUCCESS,
    processYodleeWidgetCallbackSuccessWorkerWithMiddleware
  );
  yield takeEvery(
    YODLEE_HANDLE_YODLEE_WIDGET_EVENT,
    processYodleeWidgetCallbackEventWorkerWithMiddleware
  );
  yield takeEvery(
    YODLEE_HANDLE_YODLEE_WIDGET_ERROR,
    processYodleeWidgetCallbackErrorWorkerWithMiddleware
  );
}

function* handleYodleeConnectionUpdateWorker(action) {
  const globalConfig = yield select((state) => state.globalConfig);
  const accessToken = globalConfig.accessToken;
  const edgeClientId = globalConfig.edgeClientId;
  const connectionUpdateParams = {
    ...action.payload,
    accessToken,
    edgeClientId,
  };

  yield put(setIsLoading(true));
  yield call(updateYodleeConnectionRequest, connectionUpdateParams);
}

function* watchYodleeConnectionUpdate() {
  yield takeEvery(YODLEE_CONNECTION_UPDATE, handleYodleeConnectionUpdateWorker);
}

function* yodleeConfigurationUpdateWorker(action) {
  const { connectionId } = action.payload;

  const globalConfig = yield select((state) => state.globalConfig);
  const accessToken = globalConfig.accessToken;

  const widgetConfig = yield call(getWidgetConfiguration, {
    vendor,
    connectionId,
    accessToken,
  });

  yield put(setYodleeWidgetConfigurationAction(widgetConfig));
}

function* watchYodleeConfigurationUpdate() {
  yield takeLatest(
    YODLEE_WIDGET_CONFIG_UPDATE,
    yodleeConfigurationUpdateWorker
  );
}

export function* updateYodleeConfigurationWorker() {
  // race effect takes the first who completed in parallel

  yield race({
    // Start the polling
    task: call(pollYodleeWidgetConfigurationWorker),
    // Start a take effect waiting for the cancel action.
    cancel: take(YODLEE_CONFIG_CANCEL_STATUS_POLLING),
  });
}

function* pollYodleeWidgetConfigurationWorker() {
  while (true) {
    const globalConfig = yield select((state) => state.globalConfig);
    const rollbar = yield getContext("rollbar");
    const currentProvider = globalConfig.currentProvider;
    const { onError } = globalConfig.clientCallbackFunctions;

    const configParams = {
      vendor,
      connectionId: globalConfig.yodleeConnection?.id,
      accessToken: globalConfig.accessToken,
    };

    try {
      yield delay(YODLEE_WIDGET_UPDATE_DELAY);

      if (currentProvider !== vendor) {
        yield put(cancelYodleeWidgetConfigPolling()); // Cancel polling if vendor has changed
      }
      const widgetConfig = yield call(getWidgetConfiguration, configParams);

      yield put(setYodleeWidgetConfigurationAction(widgetConfig));
    } catch (e) {
      rollbar.error("Failed to perform widget config update for Yodlee", e, {
        params: configParams,
      });

      yield call(
        onError,
        ApiErrorMessagesMapper(e, { isLastVendor: globalConfig.isLastVendor })
      );
      yield call(hideFastlink);
      yield put(toggleWidgetRender());
      yield put(cancelYodleeWidgetConfigPolling());
    }
  }
}

function* handleYodleeWidgetInitWorker(action) {
  const { productRequestId, institutionId } = action.payload;

  const globalConfig = yield select((state) => state.globalConfig);
  const rollbar = yield getContext("rollbar");
  const { onError } = globalConfig.clientCallbackFunctions;
  const accessToken = globalConfig.accessToken;
  const edgeClientId = globalConfig.edgeClientId;

  try {
    yield put(setIsLoading(true));

    const connection = yield call(findOrCreateYodleeConnection, {
      productRequestId,
      institutionId,
      edgeClientId,
      accessToken,
    });
    yield put(setYodleeConnection(connection));
    const widgetConfig = yield call(getWidgetConfiguration, {
      vendor,
      connectionId: connection.id,
      accessToken,
    });
    yield put(setIsLoading(false));
    yield put(setYodleeWidgetConfigurationAction(widgetConfig));
    // Run widget configuration polling after widget configuration was set
    yield call(updateYodleeConfigurationWorker);
  } catch (e) {
    rollbar.error("Failed to init Yodlee widget", e, {
      params: action.payload,
    });

    yield call(
      onError,
      ApiErrorMessagesMapper(e, { isLastVendor: globalConfig.isLastVendor })
    );

    /// Unmount on authentication error
    if (e?.cause?.statusCode === 401) {
      return yield put(toggleWidgetRender());
    }
    /// Switch to next on bad requests and server errors
    if (
      e?.cause?.statusCode === 500 ||
      e?.cause?.statusCode === 422 ||
      e?.cause?.statusCode === 400
    ) {
      yield put(setSwitchToNextVendor());
      return yield put(setCriticalIssueOccurred());
    }
    /// Switch to the next vendor if the Sophtron code itself trows some errors
    yield put(setSwitchToNextVendor());
    yield put(setCriticalIssueOccurred());
    yield put(setIsLoading(false));
  }
}

function* watchYodleeWidgetInit() {
  yield takeEvery(YODLEE_WIDGET_INIT, handleYodleeWidgetInitWorker);
}

export default function* yodleeWidgetSagas() {
  yield spawn(watchYodleeWidgetCallbacks);
  yield spawn(watchYodleeConnectionUpdate);
  yield spawn(watchYodleeConfigurationUpdate);
  yield spawn(watchYodleeWidgetInit);
}
