import { useSelector } from "react-redux";

const Footer = () => {
  const { widgetConfiguration } = useSelector((state) => state.globalConfig);
  const footerText = widgetConfiguration?.texts?.footer_text;

  return (
    <div className="ninja-fetch-widget__footer flex flex-col items-center justify-center">
      {footerText && (
        <p className="ninja-fetch-widget__footer--text text-center mb-2">
          {footerText}
        </p>
      )}
      <div
        className={`ninja-fetch-widget__footer--ninja-logo flex flex-row items-center gap-2 ${
          !footerText ? "justify-center" : "place-content-end"
        }`}
      >
        <span className="ninja-fetch-widget__footer--ne-logo-powered">
          Powered by
        </span>
        <img
          src="https://edge-public-assets.s3.us-east-2.amazonaws.com/EDGE_horizontal_logo.png"
          alt="EdgeLogo"
          className="ninja-fetch-widget__footer--ne-logo-image"
        />
      </div>
    </div>
  );
};

export default Footer;
