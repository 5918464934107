export const BUSINESS_WIDGET_NEW_INSTITUTION_CLICKED =
  "BUSINESS/WIDGET_NEW_INSTITUTION_CLICKED";
export const BUSINESS_WIDGET_REUSE_INSTITUTION_CLICKED =
  "BUSINESS/WIDGET_REUSE_INSTITUTION_CLICKED";
export const BUSINESS_WIDGET_INSTITUTION_PRESELECTED =
  "BUSINESS/WIDGET_INSTITUTION_PRESELECTED";
export const BUSINESS_WIDGET_LANDING_DISPLAYED =
  "BUSINESS/WIDGET_LANDING_DISPLAYED";
export const BUSINESS_WIDGET_CONNECT_CLICKED =
  "BUSINESS/WIDGET_CONNECT_CLICKED";

export const BUSINESS_WIDGET_BANK_SELECTION_OPENED =
  "BUSINESS/WIDGET_BANK_SELECTION_OPENED";
export const BUSINESS_WIDGET_BANK_SELECTION_SELECTED =
  "BUSINESS/WIDGET_BANK_SELECTION_SELECTED";

export const BUSINESS_WIDGET_LOGIN_FAILED = "BUSINESS/WIDGET_LOGIN_FAILED";
export const BUSINESS_WIDGET_LOGIN_SUCCEEDED =
  "BUSINESS/WIDGET_LOGIN_SUCCEEDED";

export const BUSINESS_WIDGET_RETRY_DISPLAYED =
  "BUSINESS/WIDGET_RETRY_DISPLAYED";
export const BUSINESS_WIDGET_RETRY_CLICKED = "BUSINESS/WIDGET_RETRY_CLICKED";
export const BUSINESS_WIDGET_SEARCH_CLICKED = "BUSINESS/WIDGET_SEARCH_CLICKED";
export const BUSINESS_WIDGET_OUTDATED_USER_AGENT_DISPLAYED = "BUSINESS/WIDGET_OUTDATED_USER_AGENT_DISPLAYED";
